/* Set default font-family */
#editorBox {
  font-family: "Arial";
  font-size: 16px;
}
.ql-blank{
  min-height: 250px;
}
.ql-picker-label {
  overflow: hidden;
}
.ql-container{
  min-height: 250px;
}

.MuiBox-root .css-1e9qeup{
  background-color: #FFFFFF;
}
.ql-snow .ql-picker.ql-font{
/* width:160px; */
}
#toolbar{
  /* position: sticky; */
  top: 0;
  z-index: 10;
  background: #fff;
}
/* Set dropdown font-families */
#editorBox .ql-font span[data-label="Arial"]::before {
  font-family: "Arial";
}
#editorBox .ql-font span[data-label="Arial Black"]::before {
  font-family: "Arial Black";
}
#editorBox .ql-font span[data-label="Century Gothic"]::before {
  font-family: "Century Gothic";
}
#editorBox .ql-font span[data-label="Comic Sans MS"]::before {
  font-family: "Comic Sans MS";
}
#editorBox .ql-font span[data-label="Courier"]::before {
  font-family: "Courier";
}
#editorBox .ql-font span[data-label="Courier New"]::before {
  font-family: "Courier New";
}
#editorBox .ql-font span[data-label="Georgia"]::before {
  font-family: "Georgia";
}
#editorBox .ql-font span[data-label="Geneva"]::before {
  font-family: "Geneva";
}
#editorBox .ql-font span[data-label="Helvetica"]::before {
  font-family: "Helvetica";
}
#editorBox .ql-font span[data-label="Lucida"]::before {
  font-family: "Lucida";
}
#editorBox .ql-font span[data-label="MS Serif"]::before {
  font-family: "MS Serif";
}
#editorBox .ql-font span[data-label="New York"]::before {
  font-family: "New York";
}
#editorBox .ql-font span[data-label="Palatino"]::before {
  font-family: "Palatino";
}
#editorBox .ql-font span[data-label="Tahoma"]::before {
  font-family: "Tahoma";
}
#editorBox .ql-font span[data-label="Times New Roman"]::before {
  font-family: "Times New Roman";
}
#editorBox .ql-font span[data-label="Trebuchet MS"]::before {
  font-family: "Trebuchet MS";
}
#editorBox .ql-font span[data-label="Verdana"]::before {
  font-family: "Verdana";
}

.ql-font-arial-black {
  font-family: "Arial Black";
}
.ql-font-century-gothic {
  font-family: "Century Gothic";
}
.ql-font-comic-sans-ms {
  font-family: "Comic Sans MS";
}
.ql-font-courier {
  font-family: "Courier";
}
.ql-font-courier-new {
  font-family: "Courier New";
}
.ql-font-georgia {
  font-family: "Georgia";
}
.ql-font-geneva {
  font-family: "Geneva";
}
.ql-font-helvetica {
  font-family: "Helvetica";
}
.ql-font-lucida {
  font-family: "Lucida";
}
.ql-font-ms-serif {
  font-family: "MS Serif";
}
.ql-font-new-york {
  font-family: "New York";
}
.ql-font-palatino {
  font-family: "Palatino";
}
.ql-font-tahoma {
  font-family: "Tahoma";
}
.ql-font-times-new-roman {
  font-family: "Times New Roman";
}
.ql-font-trebuchet-ms {
  font-family: "Trebuchet MS";
}
.ql-font-verdana {
  font-family: "Verdana";
}


/* We do not set Aref Ruqaa since it is the default font */
